export const BATTLETYPES = {
  all: [
    'NM',
    'All',
    'FF',
    'OL',
    'SL',
    'SR',
    'LC',
    'FT',
    'AP',
    'SP',
    'FC',
    'NV',
    'NT',
    'OT',
    'NB',
    'NTH',
    'AT',
    'D',
    'OW',
    'M',
  ],
  year: [
    'NM',
    'All',
    'FF',
    'OL',
    'SL',
    'SR',
    'LC',
    'FT',
    'AP',
    'SP',
    'FC',
    'NV',
    'NT',
    'OT',
    'NB',
    'NTH',
    'AT',
    'D',
    'OW',
    'M',
  ],
  month: ['NM', 'All', 'FF', 'AP'],
  week: ['All'],
  day: ['All'],
};
export const BATTLETYPES_LONG = {
  NM: 'Normal',
  All: 'All',
  FF: 'First Finish',
  OL: 'One Life',
  SL: 'Slowness',
  SR: 'Survivor',
  LC: 'Last Counts',
  FT: 'Flag Tag',
  AP: 'Apple',
  SP: 'Speed',
  FC: 'Finish Count',
  NV: 'No Volt',
  NT: 'No Turn',
  OT: 'One Turn',
  NB: 'No Brake',
  NTH: 'No Throttle',
  AT: 'Always Throttle',
  D: 'Drunk',
  OW: 'One Wheel',
  M: 'Multi',
};
export const INTERNALS = [
  2,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  15,
  59,
  78,
  109,
  139,
  219,
  71,
  51,
  165,
  57,
  128,
  197,
  43,
  107,
  98,
  100,
  175,
  192,
  38,
  198,
  31,
  16,
  18,
  164,
  66,
  131,
  156,
  357,
  45,
  13,
  408,
  412,
  24,
  416,
  415,
  95,
  29,
  33,
  46,
  21,
  52,
  257,
  135,
  133,
  413,
  17,
];
